import React from "react";
import ModalWindow from "../../layout/modal/ModalWindow";
import "./style/login-form.scss";
import {IMaskInput} from "react-imask";
import {ApiEngine} from "api-engine";

interface LoginWindowProps {
  api: ApiEngine
}

interface LoginWindowState {
  login: string,
  password: string,
  email: string,
  code: string,
  codeMode: boolean,
  codeCheckMode: boolean
}

function filterCell(cell: string): string {
  return cell.replaceAll("(",")").
  replaceAll(")","").
  replaceAll("-", "").
  replaceAll("+", "");
}

export default class LoginWindow extends React.Component<LoginWindowProps, LoginWindowState> {
  passwordInput: React.RefObject<HTMLInputElement> = React.createRef()
  emailRef: React.RefObject<HTMLInputElement> = React.createRef()
  codeRef: React.RefObject<HTMLInputElement> = React.createRef()

  constructor(_props: LoginWindowProps) {
    super(_props);
    this.state = {
      login: "",
      password: "",
      email: "",
      code: "",
      codeMode: false,
      codeCheckMode: false
    }
    this.checkData = this.checkData.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.codeUpdate = this.codeUpdate.bind(this);
  }

  checkData() {
    const me = this;
    const parcel = {
      cell: filterCell(me.state.login),
      password: me.state.password
    };
    me.props.api.asyncFetch("/api/auth/sign_in", {
      method: "POST", body: JSON.stringify(parcel)
    }).then((res) => {
      if (res.jwt) {
        localStorage.setItem("jwt", res.jwt);
        document.location.reload();
      }
    });
  }

  sendCode() {
    const me = this;
    const parcel = {
      email: me.state.email
    };
    me.props.api.asyncFetchWithoutQueing("/api/email_auth/initiate", {
      method: "POST", body: JSON.stringify(parcel)
    }).then((res) => {
      if (res) {
        me.setState({
          codeCheckMode: true
        }, () => {
          me.codeRef.current?.focus();
        })
      }
    });
  }

  codeUpdate(code: string) {
    const me = this;
    me.setState({
      code: code
    }, () => {
      if (code.length < 6) return;
      const parcel = {
        email: me.state.email,
        code: code
      };
      me.props.api.asyncFetch("/api/email_auth/check", {
        method: "POST", body: JSON.stringify(parcel)
      }).then((res) => {
        if (res.jwt) {
          localStorage.setItem("jwt", res.jwt);
          document.location.reload();
        }
      });
    })
  }

  render() {
    const me = this;
    return <ModalWindow onClick={null}>
      <div className={"login-form"}>
        <h2>Вход</h2>
        { !me.state.codeMode ?
          <>
            <IMaskInput mask={"+{7}(000)000-00-00"}
             radix="."
             placeholder={"Номер телефона"}
              type={"tel"}
             value={me.state.login}
             onComplete={(value: any, mask: any) => {
               me.setState({
                 login: value
               }, () => {
                 me.passwordInput.current?.focus()
               })
             }}/>
            <input ref={me.passwordInput}
                   onChange={(e) => {
                     me.setState({
                       password: e.target.value
                     })
                   }}
                   placeholder={"Пароль"}
                   type={"password"}/>
            { me.state.login && me.state.password && <button onClick={me.checkData} className={"green"}>ВОЙТИ</button> }
            <p onClick={() => {
              me.setState({codeMode: !me.state.codeMode});
            }}>Войти по коду</p>
          </>
          :
            <>
              {me.state.codeCheckMode ? <input ref={me.codeRef}
                                               key={me.state.codeCheckMode ? "1" : "2"}
                                               onChange={(e) => {
                                                 me.codeUpdate(e.target.value);
                                               }}
                                               style={{textAlign: "center"}}
                                               placeholder={"КОД"}
                                               type={"tel"}/>
                  :
                  <>
                    <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              me.sendCode();
                            }
                          }}
                           ref={me.emailRef}
                           onChange={(e) => {
                             me.setState({
                               email: e.target.value
                             })
                           }}
                           style={{textAlign: "center"}}
                           placeholder={"Email"}
                           type={"email"}/>
                    {me.state.email.length > 4 &&
                        <button onClick={me.sendCode} className={"green"}>ОТПРАВИТЬ КОД</button>}
                  </>
              }
            </>
        }
      </div>
    </ModalWindow>
  }
}