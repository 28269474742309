import React from 'react';
import {ApiEngine} from "api-engine";
import "./style/chat-opening-button.scss";
import ModalWindow from "../../../../../layout/modal/ModalWindow";



interface ChatOpeningButtonProps {
    api: ApiEngine
    chat: any
    onClick: any
    onTouch: any
}

interface ChatOpeningButtonState {
    chatIsOpened: boolean
}


export default class ChatOpeningButton extends React.Component<ChatOpeningButtonProps, ChatOpeningButtonState> {
    touchStarted: Boolean = false
    scrollPosition: any = null
    constructor(props: ChatOpeningButtonProps) {
        super(props);
        this.state = {
            chatIsOpened: false
        };
    }

    render() {
        const me = this;
        return <div className={"chat-opening-button-container"}
                    onTouchStart = {() => {
                        me.touchStarted = true;
                        me.scrollPosition = window.scrollY;
                    }}
                    onClick={() => {
                        if (me.touchStarted) return;

                        if (me.props.onClick) {
                            me.props.onClick();
                        }
                    }}
                    onTouchEnd={() => {
                        if (me.props.onClick && !me.touchStarted) {
                            const scrollPosition = window.scrollY;
                            if (me.scrollPosition !== scrollPosition) {
                                return;
                            }
                            me.props.onClick();
                        }
                        me.touchStarted = false;
                    }}
        >
            <div className={"chat-opening-button"}>
                <i className={`fal ${me.props.chat.fontAwesomeIcon ? me.props.chat.fontAwesomeIcon : 'fa-comments'}`}></i>
                { me.props.chat.unread > 2 &&
                    <span
                        key={me.props.chat.unread}
                        className={`amount`}>{me.props.chat.unread -2 }</span> }
            </div>
            <div className={"tooltip"}>
                <span className={"title"}>{me.props.chat.title}</span>
            </div>
            <span className={"title"}>{me.props.chat.title} { me.props.chat.partner && me.props.chat.partner?.last_name}</span>
        </div>
    }
}