import React from "react";
import {ApiEngine} from "api-engine";
import Comments from "./comments/Comments";
import {VisibilityObserver} from "reactjs-visibility";

interface NewsItemProps {
    api: ApiEngine;
    news: any;
    filterCallback: (filter: string) => void;
}

interface NewsItemState {   
    likes: number;
}

export default class NewsItem extends React.Component<NewsItemProps, NewsItemState> {
    
    constructor(props: NewsItemProps) {
        super(props);
        this.state = {
            likes: props.news.likes || 0
        };
        this.getLikes = this.getLikes.bind(this);
        this.postLike = this.postLike.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getLikes();
    }

    postLike() {
        const me = this;
        me.props.api.asyncFetchWithoutQueing(`/api/news-articles/${me.props.news.id}/like`, {method: "POST"}).
        then((res: any) => {
            me.getLikes(false);
        });
    }

    getLikes(andThenAgain = true) {
        const me = this;
        me.props.api.asyncFetch(`/api/news-articles/${me.props.news.id}/likes`, {}).then((likes: number) => {
            me.setState({likes: likes}, () => {
                if (andThenAgain) {
                    setTimeout(me.getLikes, 10000);
                }
            });
        });
    }
    
    render() {
        const me = this;
        
        return <div className="news-item">
        <div className={"header-zone"}>
            <div className="news-item-header">
                <div className="title-zone">
                    <h3>{me.props.news.title}</h3>
                    <div className="likes">
                        <i className="fas fa-heart" onClick={me.postLike}/>&nbsp;&nbsp;{me.state.likes || 0}
                    </div>
                </div>
                <div className={"time"}>
                    { me.props.news.body && <><i className={"fas fa-clock"}/>&nbsp;&nbsp;{Math.round(me.props.news.body.length / 53 / 60 + 1 )} мин</> }
                </div>
                <p className={"lead"}>{me.props.news.lead}</p>
                {/*<span onClick={() => this.setState({ activeNewsArticle: news })} >ЧИТАТЬ</span>*/}
            </div>
        </div>
        <div className={"keywords"}>{me.props.  news.keywords.split(";").map((x: any) => {
            return <div className={"keyword"}>{x}</div>;
        })}</div>
        <br/>
        <img src={`${me.props.api.serverUrl}/${me.props.news.thumbnail_url}`} alt={me.props.news.title}/>

        <div className="news-item-body" dangerouslySetInnerHTML={{__html: me.props.news.body}}></div>
        <div className="news-item-footer">
            <p>{me.props.news.date}</p>
        </div>
            <VisibilityObserver onChangeVisibility={(_e) => {
                    me.props.api.asyncFetchWithoutQueing(`/api/news/${me.props.news.id}/read`, {}, 0)
            }}></VisibilityObserver>
        <Comments news = {me.props.news} api={me.props.api}/>
                
    </div>
    }
}
