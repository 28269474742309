import React from "react";
import {ApiEngine} from "api-engine";
import "./style/trading-points-page.scss";
import TradingPointComponent from "../../domains/trading_points/common/TradingPointComponent";
import TradingPointsChooser from "./components/trading_points_chooser/TradingPointsChooser";

interface TradingPointsPageContentProps {
    api: ApiEngine
}

interface TradingPointsPageContentState {
    tradingPoints: any[];
    showTradingPoints: any[];
    filter: string;
    activeTradingPoint: any;
    partners: any[];
    activePartner?: any
    showingPartnerFilter: boolean
    partnerFilterValue: string
}

export default class TradingPointsPageContent extends React.Component<TradingPointsPageContentProps, TradingPointsPageContentState> {

    constructor(props: TradingPointsPageContentProps) {
        super(props);
        this.state = {
            tradingPoints: [],
            showTradingPoints: [],
            filter: "",
            activeTradingPoint: null,
            partners: [],
            activePartner: null,
            showingPartnerFilter: false,
            partnerFilterValue: ""

        }
        this.getTradingPoints = this.getTradingPoints.bind(this);
    }

    componentDidMount() {
        this.getTradingPoints();
    }

    extract(companies: any[]): any[] {
        let res = [];
        for(let c of companies) {
            if (!c.partner) continue;
            if (res.map((x: any) => {
                return x.id
            }).indexOf(c.partner.id) === -1){
                c.partner.name = `${c.partner.last_name} ${c.partner.first_name} ${c.partner.middle_name}`;
                res.push(c.partner);
            }
        }
        return res;
    }


    getTradingPoints() {
        const url = "/api/companies";
        const me = this;
        this.props.api.asyncFetch(url, {}).then((response) => {
            let partners = me.extract(response.companies)
            this.setState({ partners: partners, activePartner: null, tradingPoints: response.companies, showTradingPoints: response.companies });
        });
    }

    render() {
        const me = this;
        return <div className={"trading-points-page-content"}>
            { me.state.partners.length > 0 &&
                <>
                    <div
                         className={"partners-list"}>
                        <h3>Партнер:&nbsp;</h3>
                        <i
                            onClick={() => {
                                me.setState({
                                    showingPartnerFilter: !me.state.showingPartnerFilter,
                                    partnerFilterValue: ""
                                })
                            }}
                            className={"fas fa-filter ml-4"}
                            style={{cursor: "pointer"}}/>
                        {
                            me.state.showingPartnerFilter &&
                            <input
                                placeholder={"Имя партнера"}
                                onChange={(e) => {
                                    me.setState({
                                        partnerFilterValue: e.target.value
                                    })
                                }}
                            />
                        }
                        <p
                            key={`${me.state.activePartner ? JSON.stringify(me.state.activePartner) : ""} `}
                            className={`${!me.state.activePartner ? 'active-partner' : ''}`}
                            onClick={() => {
                                me.setState({activePartner: null, activeTradingPoint: null})
                            }}
                            >ВСЕ</p>
                        {
                            me.state.partners.map((p, pIndex) => {
                                if ((me.state.partnerFilterValue) &&  (-1 === JSON.stringify(p).toLowerCase().indexOf(me.state.partnerFilterValue.toLowerCase()))) return null;
                                return <p
                                    key={`partner-${pIndex} ` + `${me.state.activePartner ? JSON.stringify(me.state.activePartner) : ""} `}
                                    className={`${me.state.activePartner && (me.state.activePartner.id === p.id ? 'active-partner' : '')}`}
                                    onClick={() => {
                                        me.setState({activePartner: p, activeTradingPoint: null})
                                    }}
                                    >{p.last_name} {p.first_name} {p.middle_name}</p>
                            })
                        }
                    </div>
                    <h1>{me.state.activePartner?.name}</h1>
                </>
            }
            <div className="trading-points-list-container">
                { me.state.tradingPoints && <TradingPointsChooser
                    activeTradingPoint={me.state.activeTradingPoint}
                    tradingPoints={me.state.tradingPoints}
                    onChoose={(_tradingPoint: any) => {
                        me.setState({activeTradingPoint: _tradingPoint});
                    }}
                /> }

                {me.state.activeTradingPoint &&
                    <TradingPointComponent
                        key={me.state.activeTradingPoint.id}
                        tradingPoint={me.state.activeTradingPoint}
                        api={me.props.api}/>
                }
            </div>
        </div>
    }
}