import React from "react";
import "./style/trading-points-chooser.scss";

interface TradingPointsChooserProps {
    tradingPoints: any[]
    onChoose: (tradingPoint: any) => void
    activeTradingPoint?: any
    hideMetrics?: boolean
}

interface TradingPointsChooserState {
    filter: string
    showTradingPoints: any[]
    activeTradingPoint: any
}

export default class TradingPointsChooser extends React.Component<TradingPointsChooserProps, TradingPointsChooserState> {
    constructor(_props: TradingPointsChooserProps) {
        super(_props);
        this.state = {
            filter: "",
            showTradingPoints: [],
            activeTradingPoint: null
        }
    }
    render() {
        const me = this;
        return <div className={"trading-point-chooser"}>
            <div className={"search-bar"}>
                <input type="text" placeholder="Фильтр" value={me.state.filter} onChange={(e) => me.setState({
                    filter: e.target.value,
                    showTradingPoints: me.props.tradingPoints.filter((tradingPoint: any) => tradingPoint.title.toLowerCase().includes(e.target.value.toLowerCase()))
                })}/>
            </div>
            <div className="trading-points-list">
                {me.props.tradingPoints.map((tradingPoint: any) => {
                    return <div
                        className={`trading-point-button ${me.state.activeTradingPoint?.id === tradingPoint.id ? "trading-point-active" : ""}`}
                        key={tradingPoint.id} onClick={() => {
                            me.setState({activeTradingPoint: tradingPoint}, () => {
                                if (me.props.onChoose) {
                                    me.props.onChoose(tradingPoint);
                                }
                            });
                    }}>
                        <div className={"metrics"}>
                            <h5>{tradingPoint.title}</h5>
                            { !me.props.hideMetrics &&
                                <p className={"target-plan"}><i
                                    className={"fal fa-ruble-sign"}/>{tradingPoint.targetPlan ? tradingPoint.targetPlan.toLocaleString() : "-"}
                                </p>
                            }
                        </div>
                    </div>
                })}
            </div>
        </div>
    }
}