import React from "react";
import "./style/inspections.scss";
import { ApiEngine } from "api-engine";
import User from "../../domains/actors/users/User";
import CircularDial from "../../components/metric_widgets/dials/circular/CircularDial";
import TradingPointsChooser from "../trading_points/components/trading_points_chooser/TradingPointsChooser";

interface InspectionsListProps {    
    api: ApiEngine;
    user: User;
}

interface InspectionsListState {
    listOfInspections: any[];
    typesOfPatterns: any[];
    activePatternType: any | null;
    loading: boolean;
    activeCompany: any
    availableCompanies: null | any[]
}

export default class InspectionsList extends React.Component<InspectionsListProps, InspectionsListState> {
    constructor(props: InspectionsListProps) {
        super(props);
        this.state = {
            listOfInspections: [],
            typesOfPatterns: [],
            activePatternType: null,
            loading: true,
            activeCompany: null,
            availableCompanies: null
        }

        this.getTradingPoints = this.getTradingPoints.bind(this);
        this.getListOfInspections = this.getListOfInspections.bind(this);
        this.getListOfInspectionTypes = this.getListOfInspectionTypes.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getTradingPoints();

    }

    async getListOfInspections() {
        const url = `/api/trading_point_inspections/for/company/${this.state.activeCompany.id}/with_pattern/${this.state.activePatternType.name}`;
        const me = this;
        return new Promise((resolve, reject) => {
            me.setState({
                loading: true
            }, () => {
                me.props.api.asyncFetch(url, {})
                    .then((response: any) => {
                        if (response.inspections) {
                            setTimeout(() => {
                                me.setState({listOfInspections: response.inspections, loading: false}, () => {
                                    resolve(true);
                                });
                            }, 2000)
                        } else {
                            me.setState({loading: false}, () => {
                                reject(false);
                            })
                        }
                    });
            })

        });
    }

    extractTypesOfPatterns() {
        const me = this;
        const listOfInspections = me.state.listOfInspections;
        const typesOfPatterns = listOfInspections
            .map((inspection: any) => inspection.pattern.name)
            .filter((name: string, index: number, array: string[]) => array.indexOf(name) === index);
        me.setState({typesOfPatterns: typesOfPatterns.sort()});
    }

    getListOfInspectionTypes() {
        const me = this;
        const url = `/api/trading_point_inspections/patterns/for/company/${me.state.activeCompany.id}`;
        this.props.api.asyncFetchWithoutQueing(url, {}).then((response: any) => {
            this.setState({ typesOfPatterns: response.patterns});
        });
    }

    getTradingPoints() {
        const url = "/api/companies";
        const me = this;
        this.props.api.asyncFetch(url, {}).then((response) => {
            this.setState({ availableCompanies: response.companies});
        });
    }
    
    render() {
        const me = this;
        if (!me.state.availableCompanies) {
            return <div className={"inspections-list"}>
                <h1>Проверки</h1>
                <p>Загрузка списка кофеен</p>
            </div>
        }
        return <div className={"inspections-list"}>
            <h1>Проверки</h1>
            <br/>
            <div className={"inspections-content"}>
                <TradingPointsChooser tradingPoints={me.state.availableCompanies}
                                      hideMetrics={true}
                                      onChoose={(_x) => {
                                          me.setState({activePatternType: null, activeCompany: _x, loading: false, listOfInspections: []}, () => {
                                              me.getListOfInspectionTypes()
                                              // me.getListOfInspections().then(() => {
                                              //     me.extractTypesOfPatterns();
                                              // });
                                          })
                                      }}
                />
                { me.state.activeCompany &&
                    <div className={"flex"}>
                        <div className="inspections-filters">
                            <p>Вид проверки</p>
                            {me.state.typesOfPatterns.map((type: any, index: number) => {
                                return <button
                                    className={me.state.activePatternType === type ? "active" : ""}
                                    key={`${type}-${index}-inspections`}
                                    onClick={() => me.setState({activePatternType: type}, () => {
                                        me.getListOfInspections()
                                    })}>{type.name}</button>
                            })}
                        </div>
                        {me.state.activePatternType &&
                            <div className="inspections">
                                { me.state.loading ?
                                    <p>Загрузка</p>
                                    :
                                    me.state.listOfInspections.length === 0 && <p>Проверок не обнаружено</p>
                                }
                                {
                                    me.state.listOfInspections.map((inspection: any) => {
                                        if (me.state.activePatternType !== null && inspection.pattern.name !== me.state.activePatternType.name) {
                                            return null;
                                        }
                                        if (!inspection.company) {
                                            return null;
                                        }
                                        return <div className="inspection-item"
                                                    key={inspection.id}
                                                    style={
                                                        {
                                                            background: `linear-gradient(-135deg, #${inspection.pattern.color} 0%, var(--black) 2%, var(--black) 100%)`,
                                                            borderLeft: `4px solid #${inspection.pattern.color}`
                                                        }
                                                    }
                                        >
                                            <div className="inspection-item__info">
                                                <p className="inspection-item__info__pattern">{inspection.data.pattern.name}</p>
                                                <p className="inspection-item__info__company">{inspection.company?.title}</p>
                                                <div className="inspection-item__info__dates">
                                                    <i className="fas fa-calendar-alt"/>
                                                    <p className="inspection-item__dates__started-at">{new Date(inspection.startedAt).toLocaleString()}</p>
                                                    <p className="inspection-item__dates__finished-at">{new Date(inspection.finishedAt).toLocaleString()}</p>
                                                </div>
                                            </div>
                                            { inspection.maxRate > 0 &&
                                                <CircularDial value={inspection.factRate} maxValue={inspection.maxRate}/>}
                                            {/* {JSON.stringify(inspection)} */}
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                }
            </div>

        </div>
    }
}
